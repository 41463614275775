"use client";

import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "@src/config/theme";
import { snackbarConfig, swrConfigObject } from "@src/constants";
import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import NextNProgress from "nextjs-progressbar";
import { SnackbarProvider } from "notistack";
import { SWRConfig } from "swr";

export const DefaultProviders = ({
  children,
  session,
}: {
  children: React.ReactNode;
  session: any;
}) => {
  let GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

  return (
    <>
      <Analytics />

      {GTM_ID && (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `}
        </Script>
      )}

      <SnackbarProvider {...snackbarConfig} maxSnack={3}>
        <NextNProgress />
        <SWRConfig
          value={{
            ...swrConfigObject,
          }}
        >
          <SessionProvider session={session}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </SessionProvider>
        </SWRConfig>
      </SnackbarProvider>
    </>
  );
};
